import { List, Datagrid, TextField, DateField, EditButton } from 'react-admin';
import { Card, CardContent, withStyles } from '@material-ui/core';

import { AppExporter, AppPagination, SearchFilter } from '../../components';
import { MembershipStatusFilter } from './filters';

const SidebarCard = withStyles(theme => ({
  root: {
    order: -1, // display on the left rather than on the right of the list
    width: '15em',
    marginRight: '0.3em',
  },
}))(Card);

const FilterSidebar = () => (
  <SidebarCard>
    <CardContent>
      <MembershipStatusFilter />
    </CardContent>
  </SidebarCard>
);

export default function (props) {
  return (
    <List
      {...props}
      sort={{ field: 'id', order: 'DESC' }}
      bulkActionButtons={false}
      export={AppExporter}
      filters={<SearchFilter />}
      pagination={<AppPagination />}
      aside={<FilterSidebar />}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="membership_title" />
        <TextField source="user_username" />
        <DateField source="start_date" />
        <DateField source="end_date" />
        <TextField source="membership_status_display" />
        <EditButton />
      </Datagrid>
    </List>
  );
}
