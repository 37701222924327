import { FilterList, FilterListItem } from 'react-admin';

import { PortfolioStatus } from './constants';

export const StatusFilter = () => (
  <FilterList label="Portfolio status">
    {PortfolioStatus.map(item => (
      <FilterListItem label={item.name} value={{ portfolio_status: item.id }} />
    ))}
  </FilterList>
);
