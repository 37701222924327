import {
  List,
  Datagrid,
  TextField,
  EditButton,
  BooleanField,
} from 'react-admin';
import { Card, CardContent, withStyles } from '@material-ui/core';

import { AppExporter, AppPagination, SearchFilter } from '../../components';
import { IsAdminPromoteFilter } from './filters';

const SidebarCard = withStyles(theme => ({
  root: {
    order: -1, // display on the left rather than on the right of the list
    width: '15em',
    marginRight: '0.3em',
  },
}))(Card);

const FilterSidebar = () => (
  <SidebarCard>
    <CardContent>
      <IsAdminPromoteFilter />
    </CardContent>
  </SidebarCard>
);

export default function (props) {
  return (
    <List
      {...props}
      sort={{ field: 'id', order: 'ASC' }}
      bulkActionButtons={false}
      export={AppExporter}
      filters={<SearchFilter />}
      pagination={<AppPagination />}
      aside={<FilterSidebar />}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="rank_title" />
        <TextField source="user_username" />
        <BooleanField source="is_admin_promote" />
        <EditButton />
      </Datagrid>
    </List>
  );
}
