import { Admin, fetchUtils, Resource } from 'react-admin';
import { createTheme } from '@material-ui/core/styles';

import { authProvider, drfProvider } from './providers';

import users from './sections/users';
import accounts from './sections/accounts';
import transactions from './sections/transactions';
import banks from './sections/banks';
import blockchainWallets from './sections/blockchainWallets';
import referrals from './sections/referrals';
import referralBonusSettings from './sections/referralBonusSettings';
import passwords from './sections/passwords';
import withdrawalSettings from './sections/withdrawalSettings';
import withdrawalBanks from './sections/withdrawalBanks';
import withdrawalBlockchains from './sections/withdrawalBlockchains';
import memberships from './sections/memberships';
import membershipUsers from './sections/membershipUsers';
import portfolios from './sections/portfolios';
import portfolioUsers from './sections/portfolioUsers';
import tierBonusSettings from './sections/tierBonusSettings';
import tierBonuses from './sections/tierBonuses';
import dividendBonusSettings from './sections/dividendBonusSettings';
import dividendBonuses from './sections/dividendBonuses';
import ranks from './sections/ranks';
import rankUsers from './sections/rankUsers';
import generationBonusSettings from './sections/generationBonusSettings';
import generationBonuses from './sections/generationBonuses';

const theme = createTheme({
  palette: {
    primary: {
      main: '#72D500',
    },
    secondary: {
      main: '#81CE29',
    },
  },
});

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

export default function App() {
  return (
    <Admin
      theme={theme}
      authProvider={authProvider}
      dataProvider={drfProvider(process.env.REACT_APP_API_HOST, httpClient)}
    >
      <Resource name="users/switch" edit={users.edit} />
      <Resource name="users" {...users} />
      <Resource
        name="referrals/bonuses/settings"
        {...referralBonusSettings}
        options={{ label: 'Referral bonus settings' }}
      />
      <Resource name="referrals" {...referrals} />
      <Resource name="accounts" {...accounts} />
      <Resource name="transactions" {...transactions} />
      <Resource name="banks" {...banks} />
      <Resource
        name="blockchain/wallets"
        {...blockchainWallets}
        options={{ label: 'Blockchain wallets' }}
      />
      <Resource
        name="withdrawals/settings"
        {...withdrawalSettings}
        options={{ label: 'Withdrawal settings' }}
      />
      <Resource
        name="withdrawals/banks"
        {...withdrawalBanks}
        options={{ label: 'Withdrawal banks' }}
      />
      <Resource
        name="withdrawals/blockchains"
        {...withdrawalBlockchains}
        options={{ label: 'Withdrawal blockchains' }}
      />
      <Resource name="memberships/users" {...membershipUsers} />
      <Resource name="memberships" {...memberships} />
      <Resource name="portfolios/users" {...portfolioUsers} />
      <Resource name="portfolios" {...portfolios} />
      <Resource name="ranks/users" {...rankUsers} />
      <Resource name="ranks" {...ranks} />
      <Resource name="tiers/bonuses/settings" {...tierBonusSettings} />
      <Resource name="tiers/bonuses" {...tierBonuses} />
      <Resource name="dividends/bonuses/settings" {...dividendBonusSettings} />
      <Resource name="dividends/bonuses" {...dividendBonuses} />
      <Resource
        name="generations/bonuses/settings"
        {...generationBonusSettings}
        options={{ label: 'Management bonus settings' }}
      />
      <Resource
        name="generations/bonuses"
        {...generationBonuses}
        options={{ label: 'Management bonuses' }}
      />
      {/* <Resource name="tiers/bonuses" {...tierBonuses} />       */}
      <Resource name="passwords" {...passwords} />
    </Admin>
  );
}
