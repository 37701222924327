import {
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  ArrayField,
  SingleFieldList,
  ImageField,
  BooleanInput,
  SelectInput,
  TopToolbar,
  EditButton,
} from 'react-admin';

import { AppToolbar } from '../../components';

import { UserStatus } from './constants';

const EditActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <EditButton basePath="/passwords" record={data} label="Reset Password" />
    <EditButton basePath="switch" record={data} label="Switch" icon={false} />
  </TopToolbar>
);

export default function UserEdit(props) {
  return (
    <Edit {...props} undoable={false} actions={<EditActions />}>
      <SimpleForm redirect={false} toolbar={<AppToolbar />}>
        <TextInput disabled source="id" />
        <TextInput disabled source="username" label="Username" />
        <TextInput source="last_name" />
        <TextInput source="first_name" />
        <TextInput source="fullname_pinyin" />
        <TextInput source="email" />
        <TextInput source="details.national_id" />
        <TextInput source="details.nationality" />
        <DateInput source="details.date_of_birth" />
        <TextInput source="contact.country" />
        <TextInput source="contact.address_1" />
        <TextInput source="contact.address_2" />
        <TextInput source="contact.country_code" />
        <TextInput source="contact.contact_no" />
        <ArrayField source="attachments" fieldKey="upload">
          <SingleFieldList linkType={false}>
            <ImageField source="url" />
          </SingleFieldList>
        </ArrayField>
        <BooleanInput source="is_active" />
        <TextInput source="remark" multiline fullWidth />
        <SelectInput source="status" choices={UserStatus} />
      </SimpleForm>
    </Edit>
  );
}
