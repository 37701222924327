import { FilterList, FilterListItem } from 'react-admin';

import { IsAdminPromote } from './constants';

export const IsAdminPromoteFilter = () => (
  <FilterList label="Is admin promote">
    {IsAdminPromote.map(item => (
      <FilterListItem label={item.name} value={{ is_admin_promote: item.id }} />
    ))}
  </FilterList>
);
