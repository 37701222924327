import { List, Datagrid, TextField, EditButton } from 'react-admin';
import { Card, CardContent, withStyles } from '@material-ui/core';

import { AppPagination, AppExporter, SearchFilter } from '../../components';
import { StatusFilter, IsActiveFilter } from './filters';

const SidebarCard = withStyles(theme => ({
  root: {
    order: -1, // display on the left rather than on the right of the list
    width: '15em',
    marginRight: '0.3em',
  },
}))(Card);

const FilterSidebar = () => (
  <SidebarCard>
    <CardContent>
      <StatusFilter />
      <IsActiveFilter />
    </CardContent>
  </SidebarCard>
);

export default function UserList(props) {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      exporter={AppExporter}
      filters={<SearchFilter />}
      sort={{ field: 'id', order: 'DESC' }}
      pagination={<AppPagination />}
      aside={<FilterSidebar />}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="username" />
        <TextField source="last_name" label="Last name" />
        <TextField source="first_name" label="First name" />
        <TextField source="email" label="Email" />
        <TextField source="date_joined" label="Joined on" />
        <TextField source="status_display" label="Status" />
        <EditButton />
      </Datagrid>
    </List>
  );
}
