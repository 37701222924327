import { FilterList, FilterListItem } from 'react-admin';

import { TransactionStatus, TransactionTypes } from './constants';

export const TransactionStatusFilter = () => (
  <FilterList label="Transaction status">
    {TransactionStatus.map(item => (
      <FilterListItem
        label={item.name}
        value={{ transaction_status: item.id }}
      />
    ))}
  </FilterList>
);

export const TransactionTypeFilter = () => (
  <FilterList label="Transaction type">
    {TransactionTypes.map(item => (
      <FilterListItem label={item.name} value={{ transaction_type: item.id }} />
    ))}
  </FilterList>
);
