import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  NumberInput,
} from 'react-admin';

import { AppToolbar } from '../../components';

import { TransactionStatus } from './constants';

export default function (props) {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<AppToolbar />} redirect={false}>
        <TextInput disabled source="id" />
        <TextInput disabled source="portfolio_user_portfolio_title" />
        <TextInput disabled source="portfolio_user_user_username" />
        <NumberInput disabled source="credit_payout" />
        <NumberInput disabled source="bonus_payout" />
        <NumberInput disabled source="total_payout" />
        <SelectInput source="transaction_status" choices={TransactionStatus} />
      </SimpleForm>
    </Edit>
  );
}
