import {
  List,
  Datagrid,
  TextField,
  NumberField,
  BooleanField,
  EditButton,
} from 'react-admin';
import { Card, CardContent, withStyles } from '@material-ui/core';

import { AppPagination, AppExporter, SearchFilter } from '../../components';
import { AccountTypeFilter, IsEnableFilter } from './filters';

const SidebarCard = withStyles(theme => ({
  root: {
    order: -1, // display on the left rather than on the right of the list
    width: '15em',
    marginRight: '0.3em',
  },
}))(Card);

const FilterSidebar = () => (
  <SidebarCard>
    <CardContent>
      <AccountTypeFilter />
      <IsEnableFilter />
    </CardContent>
  </SidebarCard>
);

export default function AccountList(props) {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      exporter={AppExporter}
      filters={<SearchFilter />}
      sort={{ field: 'id', order: 'DESC' }}
      pagination={<AppPagination />}
      aside={<FilterSidebar />}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="username" />
        <TextField source="account_type_display" label="Account" />
        <NumberField source="available_balance" label="Balance" />
        <BooleanField source="is_enable" />
        <TextField source="date_created" label="Date created" />
        <EditButton />
      </Datagrid>
    </List>
  );
}
