import { FilterList, FilterListItem } from 'react-admin';

import { MembershipStatus } from './constants';

export const MembershipStatusFilter = () => (
  <FilterList label="Membership status">
    {MembershipStatus.map(item => (
      <FilterListItem
        label={item.name}
        value={{ membership_status: item.id }}
      />
    ))}
  </FilterList>
);
