import {
  List,
  Datagrid,
  TextField,
  NumberField,
  EditButton,
} from 'react-admin';

import { AppExporter, AppPagination, SearchFilter } from '../../components';

export default function (props) {
  return (
    <List
      {...props}
      sort={{ field: 'id', order: 'ASC' }}
      bulkActionButtons={false}
      export={AppExporter}
      filters={<SearchFilter />}
      pagination={<AppPagination />}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="title" />
        <NumberField source="aum" />
        <EditButton />
      </Datagrid>
    </List>
  );
}
