export const settingsTypes = [
  { id: 1, name: 'Withdrawal block' },
  { id: 2, name: 'Bank service fee (%)' },
  { id: 3, name: 'Bank minimum withdrawal' },
  { id: 4, name: 'Blockchain service fee (%)' },
  { id: 5, name: 'Blockchain minimum withdrawal' },
  { id: 6, name: 'Blockchain gas fee' },
  { id: 7, name: 'Bank first service fee' },
  { id: 8, name: 'Blockchain first service fee' },
];
