import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  BooleanInput,
} from 'react-admin';

import { AppToolbar } from '../../components';

export default function (props) {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<AppToolbar enableDelete="true" />} redirect={false}>
        <TextInput disabled source="id" />
        <ReferenceInput
          label="Rank"
          source="rank"
          reference="ranks"
          filterToQuery={searchText => ({ search: searchText })}
        >
          <AutocompleteInput optionText="title" />
        </ReferenceInput>
        <TextInput disabled source="user_username" />
        <BooleanInput source="is_admin_promote" />
      </SimpleForm>
    </Edit>
  );
}
