import {
  List,
  Datagrid,
  TextField,
  NumberField,
  SelectField,
  DateField,
  EditButton,
} from 'react-admin';
import { Card, CardContent, withStyles } from '@material-ui/core';

import { AppExporter, AppPagination, SearchFilter } from '../../components';

import { TransactionStatus } from '../transactions/constants';
import { WithdrawalStatusFilter } from './filters';
import { BulkActionButtons } from './bulkActionButtons';

const SidebarCard = withStyles(theme => ({
  root: {
    order: -1, // display on the left rather than on the right of the list
    width: '15em',
    marginRight: '0.3em',
  },
}))(Card);

const FilterSidebar = () => (
  <SidebarCard>
    <CardContent>
      <WithdrawalStatusFilter />
    </CardContent>
  </SidebarCard>
);

export default function (props) {
  return (
    <List
      {...props}
      sort={{ field: 'id', order: 'DESC' }}
      bulkActionButtons={<BulkActionButtons />}
      export={AppExporter}
      filters={<SearchFilter />}
      pagination={<AppPagination />}
      aside={<FilterSidebar />}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField
          source="account_details.account_type_display"
          label="Account"
        />
        <TextField source="account_details.username" label="user" />
        <NumberField source="withdrawal_transaction.amount" label="Amount" />
        <DateField source="withdrawal_date" showTime />
        <SelectField
          source="withdrawal_transaction.transaction_status"
          choices={TransactionStatus}
          label="Status"
        />
        <EditButton />
      </Datagrid>
    </List>
  );
}
