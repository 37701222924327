import React from 'react';
import { Fragment } from 'react';

import {
  Button,
  useUpdateMany,
  useRefresh,
  useNotify,
  useUnselectAll,
} from 'react-admin';

const StatusButton = props => {
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [updateMany, { loading }] = useUpdateMany(
    'withdrawals/banks',
    props.selectedIds,
    {
      withdrawal_transaction: {
        transaction_status: props.status_value,
      },
    },
    {
      onSuccess: () => {
        refresh();
        notify('Records updated');
        unselectAll('withdrawals');
      },
      onFailure: error => notify('Error: records not updated', 'warning'),
    }
  );

  return <Button label={props.label} disabled={loading} onClick={updateMany} />;
};

export const BulkActionButtons = props => (
  <Fragment>
    <StatusButton {...props} key={1} label="Authorized" status_value={1} />
    <StatusButton {...props} key={2} label="Pending" status_value={2} />
    <StatusButton {...props} key={3} label="Voided" status_value={3} />
    <StatusButton {...props} key={4} label="Processing" status_value={4} />
    <StatusButton {...props} key={5} label="Rejected" status_value={5} />
  </Fragment>
);
