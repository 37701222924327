import { FilterList, FilterListItem } from 'react-admin';

import { TransactionStatus } from './constants';

export const StatusFilter = () => (
  <FilterList label="Transaction status">
    {TransactionStatus.map(item => (
      <FilterListItem
        label={item.name}
        value={{ transaction_status: item.id }}
      />
    ))}
  </FilterList>
);
